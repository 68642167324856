export const updateCustomFieldProps = (customFieldProps, selectedOptions) => {
  const { options } = customFieldProps;

  // Clone the existing options to avoid mutating the original array
  let updatedOptions = [...options];

  // Array to store new selected options (with the new 'item' keys)
  const newSelectedOptions = [];

  // Start the key index from the last key in the existing options
  let currentKeyIndex = updatedOptions.length + 1;

  // Process each custom feature in selectedOptions
  selectedOptions.forEach(customFeature => {
    const newKey = `item${currentKeyIndex}`; // Generate a new unique key
    const newOption = {
      key: newKey,
      label: customFeature, // Label is the custom feature (e.g., 'test1', 'test2')
    };

    // Add new option to the updatedOptions array
    updatedOptions.push(newOption);

    // Add the new key to the newSelectedOptions array
    newSelectedOptions.push(newKey);

    // Increment key index for the next custom option
    currentKeyIndex += 1;
  });

  // Return updated customFieldProps with the modified options and selectedOptions
  return {
    ...customFieldProps,
    options: updatedOptions, // Updated options array with new items
    selectedOptions: newSelectedOptions, // Replace selectedOptions with new keys
  };
};
